<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :extraParams="extraParams"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
        <v-button
          text="返回"
          v-if="extraParams.labelLevel > 1"
          @click="goBack"
        ></v-button>
      </template>
      <template #searchSlot>
        <v-input label="标签名称" clearable v-model="searchParam.labelName" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="toEdit(scope.row)" />
        <!-- <v-button text="查看下级" type="text" @click="viewLevel(scope.row)" /> -->
      </template>
    </v-list>
  </div>
</template>

<script>
import { getLabelInfoListUrl } from "./api.js";
import { createAlinkVNode } from "@/utils/utils.js";

export default {
  data() {
    return {
      timer: null,
      searchParam: {
        labelName: "", // 标签名称
      },
      // parentName: "", // 记录父标签名称
      tableUrl: getLabelInfoListUrl,
      headers: [
        {
          prop: "labelName",
          label: "商户标签名称",
        },
        // {
        //   prop: "parentName",
        //   label: "父级标签",
        //   formatter: (row, prop) => {
        //     return `${row[prop] || "--"}`;
        //   },
        // },
        // {
        //   prop: "labelLevel",
        //   label: "级别",
        //   formatter: (row, prop) => {
        //     return `${row[prop]}级标签`;
        //   },
        // },
        {
          prop: "remarks",
          label: "标签说明",
        },
        {
          prop: "relatedUserNum",
          label: "用户人数",
          align: "center",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              text: row[prop],
              cb: () => this.toUserLabelList(row),
            }),
        },
      ],
    };
  },
  computed: {
    extraParams() {
      // const { labelLevel, parentId } = this.$route.query;
      return {
        // parentId: parentId || "",
        parentId: "",
        labelType: 2, // "标签类别 1：用户标签；2：商户标签
        // labelLevel: labelLevel || 1,
        labelLevel: "",
      };
    },
    // parentName() {
    //   return this.$route.query.parentName;
    // },
  },
  created() {
    // const { labelLevel, parentName } = this.$route.query;
    // console.log(labelLevel, JSON.stringify(this.$route.query), "labelLevel");
    // this.parentName = parentName || "";
    const _this = this;
    // this.timer = setInterval(() => {
    //   if (_this.$store.state.app.breadList.length > 0) {
    //     return;
    //   }
    //   _this.$setBreadList([
    //     { title: `${this.extraParams.labelLevel || 1}级标签` },
    //   ]);
    // }, 100);
  },
  beforeDestroy() {
    // this.$clearBreadList();
    // if (this.timer) {
    //   clearInterval(this.timer);
    // }
  },
  methods: {
    toUserLabelList(row) {
      this.$router.push({
        name: "businessNumberList",
        query: {
          labelId: row.id,
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    toAdd() {
      this.$router.push({
        name: "businessLabelForm",
        query: {
          // labelLevel: this.extraParams.labelLevel,
          // parentId: this.extraParams.parentId,
          // parentName: this.parentName,
        },
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "businessLabelForm",
        query: {
          id: row.id,
          // labelLevel: row.labelLevel,
        },
      });
    },
    // addLevel() {},
    // viewLevel(row) {
    //   this.$router.push({
    //     name: "businessLabelList",
    //     query: {
    //       parentId: row.id,
    //       labelLevel: this.extraParams.labelLevel + 1,
    //       parentName: row.labelName,
    //     },
    //   });
    // },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
